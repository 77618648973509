.custom-input-mask {
    height: 55px;
    font-size: 16px;
    border-radius: 4px;
    border: .5px solid #C5C5C5;
    padding-left: 10px;
    transition: border-color 0.3s;
    &:hover {
        border-color: #000000;
    };
    &:focus {
        border-color: #F8B043;
        outline-color: #F8B043;
    };
    &::placeholder{
        color: #949494;
    };
    &.Mui-error {
        border-color: #ff0000; 
    }
};

.custom-input-error {
    height: 55px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid red;
    padding-left: 10px;
};