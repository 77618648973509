.ant-select-selector {
    border-color: #C7C7C7 !important;
    font-size: 16px;
    min-height: 56px;
}
.ant-select-selector:hover{
    border: 1px solid #000000 !important;
}
.ant-select-selector:focus-within {
    border: 2px solid #F9B754 !important;
}
.ant-select-selection-placeholder {
    color: RGB(120, 120, 120) !important
}